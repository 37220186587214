import React from 'react'
import '../css/Email.css'

function Email() {
  return (
    <div className="email-div">
      <a href="mailto:amadywr21@gmail.com">amadywr21@gmail.com</a>

      <div className="vertical-line"></div>
    </div>
  )
}

export default Email
